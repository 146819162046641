<template>
  <div class="add-organization filter-tab">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm"
      :size="formSize">
      <el-form-item label="名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="区域" prop="region">
        <el-select v-if="provinceList.length>0" @change="getProvinceAccessAuth" v-model="ruleForm.province"
          placeholder="省份">
          <el-option v-for="item in provinceList" :key="item[0]" :value="item[0]" :label="item[1]"></el-option>
        </el-select>
        <el-select v-if="cities.length>0" v-model="ruleForm.city" @change="getCityAccessAuth" placeholder="城市">
          <el-option v-for="item in cities" :key="item[0]" :value="item[0]" :label="item[1]"></el-option>
        </el-select>
        <el-select v-if="areas.length>0" v-model="ruleForm.region" placeholder="地区">
          <el-option v-for="item in areas" :key="item[0]" :value="item[0]" :label="item[1]"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="绑定快检中心" prop="center">
        <el-input v-model="ruleForm.center"></el-input>
      </el-form-item>
      <el-form-item label="数据共享" prop="upload">
        <el-switch v-model="ruleForm.upload" 
          style="--el-switch-on-color: #13ce66; --el-switch-off-color: #666666" />
      </el-form-item>
      <el-form-item>
        <el-button class="btn-search" type="primary" @click="submitForm(ruleFormRef)">创建</el-button>
        <el-button class="btn-reset" @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getSubareaList, getAreaInfo } from 'network/area'
import { addOrganization } from 'network/org'

export default {
  name: "AddOrg",
  data() {
    return {
      ruleForm: {
        name: "",
        province: '',
        city: '',
        region: '',
        center: '',
        upload:false
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please input org name",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "Please select region of org",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "Please select org type",
            trigger: "change",
          },
        ],
      },
      provinces: [],
      cities: [],
      areas: []
    }
  },
  methods: {

    submitForm(formEl) {

      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          console.log("submit!");
          addOrganization({ org_name: formEl.model.name, area_id: formEl.model.region, check_center: formEl.model.center,upload:formEl.model.upload?"1":"0" }).then(
            res => {
              this.$message.success(res)
              let id= res.split(":")[1]
              this.$router.replace({name:'上传绑定',query:{orgName:formEl.model.name,orgId:id}})
            },
            e => {
              this.$message.warning("公司已存在" + e.data.message)
            }
          )
        } else {
          this.$message.warning('提交不合规范')
        }
      });
    },
    resetForm: (formEl) => {
      console.log(formEl)
      if (!formEl) return;
      formEl.resetFields();
    },
    getProvinceAccessAuth(areaId) {
      this.ruleForm.city = ''
      this.ruleForm.region = ''
      areaId = areaId.substring(0, 2)
      let cities = this.cityList.filter(a => a[0].startsWith(areaId))
      if (cities.length == 0)
        getSubareaList(areaId + '0000').then(
          res => {
            this.$store.dispatch('areas/addCities', res.data).then(
              (res1) => {
                this.cities = [...res1]
              }, e => { console.log(e.message) })
          },
          e => { console.log(e.message) }
        )
      else
        this.cities = cities
    },
    getCityAccessAuth(areaId) {
      this.ruleForm.region = ''
      areaId = areaId.substring(0, 4)
      let areas = this.areaList.filter(a => a[0].startsWith(areaId))
      if (areas.length == 0)
        getSubareaList(areaId + '00').then(
          res => {
            this.$store.dispatch('areas/addAreas', res.data).then(
              res1 => { this.areas = [...res1] }, e => { console.log(e.message) })
          },
          e => { console.log(e.message) }
        )
      else
        this.areas = areas
    }


  },
  mounted() {
  },
  computed: {
    ruleFormRef() {
      return this.$refs.ruleFormRef
    },
    provinceList() {
      return this.$store.getters['areas/provinces']
    },
    cityList() {
      return this.$store.getters['areas/cities']
    },
    areaList() {
      return this.$store.getters['areas/areas']
    },
    userAuth() {
      return this.$store.getters.getUserAuth
    }
  }
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  flex: 0 0 32px;
  width: calc(100% - 40px);
  padding: 8px;
  color: black;
}

.add-organization {
  width: 800px;

  .el-form {
    padding-top: 20px;
    align-items: center;
  }
}

.filter-tab /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
//输入框样式
.filter-tab /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
.filter-tab /deep/ input {
  color: #f2f2f2;
}
//按钮样式
.btn-search {
  width: 6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  border-radius: 5px;
}
.btn-reset {
  width:6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  color: white;
  border-radius: 5px;
}
</style>